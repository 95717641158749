// layouts

import Admin from "@/layouts/Admin.vue";
// import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import ResearchList from "@/views/research/ResearchList.vue";
import ResearchCheck from "@/views/research/ResearchCheck.vue";
// import Tables from "@/views/admin/Tables.vue";
import ResearchDetail from "@/views/research/ResearchDetail.vue";

// views for Auth layout

// import Login from "@/views/auth/Login.vue";
// import Register from "@/views/auth/Register.vue";

// views without layouts

// import Landing from "@/views/Landing.vue";
// import Profile from "@/views/Profile.vue";
import Index from "@/views/Index.vue";

import ReportSignList from "@/views/setting/ReportSignList.vue";
import ReportSignAdd from "@/views/setting/ReportSignAdd.vue";
import ReportSignEdit from "@/views/setting/ReportSignEdit.vue";
import ReportSignPositionList from "@/views/setting/ReportSignPositionList.vue";
import ReportSignPositionAdd from "@/views/setting/ReportSignPositionAdd.vue";
import ReportSignPositionEdit from "@/views/setting/ReportSignPositionEdit.vue";
import ReportApprovers from "@/views/setting/ReportApprovers.vue";
import ReportApproversEdit from "@/views/setting/ReportApproversEdit.vue";

import ReportResearchsList from "@/views/report/ReportResearchsList.vue";
import ReportResearchsAdd from "@/views/report/ReportResearchsAdd.vue";
import ReportResearchsEdit from "@/views/report/ReportResearchsEdit.vue";

import ReportPublicationsList from "@/views/report/ReportPublicationsList.vue";
import ReportPublicationsAdd from "@/views/report/ReportPublicationsAdd.vue";
import ReportPublicationsEdit from "@/views/report/ReportPublicationsEdit.vue";

import ReportAuthorizesList from "@/views/report/ReportAuthorizesList.vue";
import ReportAuthorizesAdd from "@/views/report/ReportAuthorizesAdd.vue";
import ReportAuthorizesEdit from "@/views/report/ReportAuthorizesEdit.vue";

import ReportPublishedList from "@/views/report/ReportPublishedList.vue";
import ReportPublishedAdd from "@/views/report/ReportPublishedAdd.vue";
import ReportPublishedEdit from "@/views/report/ReportPublishedEdit.vue";

import Report1 from "@/views/report/Report1.vue";
import Report2 from "@/views/report/Report2.vue";
import Report3 from "@/views/report/Report3.vue";
import Report4 from "@/views/report/Report4.vue";

import ApproveWait from "@/views/approve/ApproveWait.vue";
import ApproveReject from "@/views/approve/ApproveReject.vue";
import ApproveDone from "@/views/approve/ApproveDone.vue";

// routes

import EncryptServices from "../services/EncryptServices";

const commonGuard = (to, from, next) => {
  const encryptedUser = localStorage.getItem("user");
  const user = encryptedUser
    ? JSON.parse(EncryptServices.decrypt(encryptedUser))
    : null;
  if (user) {
    if (user.role == "research" && to.path == "/research-check") {
      next("/research-list");
    }
    next();
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/login",
    component: Index,
    children: [
      {
        path: "/login",
        component: Index,
      },
    ],
  },
  {
    path: "/",
    redirect: "/research-list",
    component: Admin,
    children: [
      {
        path: "/research-list",
        name: "รายการโครงการวิจัย",
        component: ResearchList,
        beforeEnter: commonGuard,
      },
      {
        path: "/research-check",
        name: "ตรวจสอบโครงการวิจัย",
        component: ResearchCheck,
        beforeEnter: commonGuard,
      },
      {
        path: "/research-details/:project_id",
        name: "รายละเอียดโครงการวิจัย",
        component: ResearchDetail,
        beforeEnter: (to, from, next) => {
          if (from.path == "/research-list" || from.path == "/research-check") {
            return next();
          }
          next("/research-list");
        },
      },
    ],
  },
 
  {
    path: "/",
    redirect: "/setting-sign",
    component: Admin,
    children: [
        {
          path: "/setting-sign",
          name: "ตั้งค่าผู้ลงนาม",
          component: ReportSignList,
          beforeEnter: commonGuard,
        },
        {
          path: "/setting-sign-add",
          name: "เพิ่มผู้ลงนาม",
          component: ReportSignAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-sign") {
              return next();
            }
            next("/setting-sign");
          },
        },
        {
          path: "/setting-sign-edit/:id",
          name: "แก้ไขผู้ลงนาม",
          component: ReportSignEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-sign") {
              return next();
            }
            next("/setting-sign");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/setting-sign-position",
    component: Admin,
    children: [
        {
          path: "/setting-sign-position",
          name: "ตั้งค่าตำแหน่ง",
          component: ReportSignPositionList,
          beforeEnter: commonGuard,
        },
        {
          path: "/setting-sign-position-add",
          name: "เพิ่มตำแหน่ง",
          component: ReportSignPositionAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-sign-position") {
              return next();
            }
            next("/setting-sign-position");
          },
        },
        {
          path: "/setting-sign-position-edit/:id",
          name: "แก้ไขตำแหน่ง",
          component: ReportSignPositionEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-sign-position") {
              return next();
            }
            next("/setting-sign-position");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/setting-approvers",
    component: Admin,
    children: [
        {
          path: "/setting-approvers",
          name: "ตั้งค่าลำดับการอนุมัติ",
          component: ReportApprovers,
          beforeEnter: commonGuard,
        },
        {
          path: "/setting-approvers-edit/:id",
          name: "แก้ไขลำดับการอนุมัติ",
          component: ReportApproversEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/setting-approvers") {
              return next();
            }
            next("/setting-approvers");
          },
        },
        
    ],
  },

  {
    path: "/",
    redirect: "/report-researchs",
    component: Admin,
    children: [
        {
          path: "/report-researchs",
          name: "ขอเบิกเงินโครงการวิจัย",
          component: ReportResearchsList,
          beforeEnter: commonGuard,
        },
        {
          path: "/report-researchs-add",
          name: "รายงานขอเบิกเงินโครงการวิจัย",
          component: ReportResearchsAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-researchs") {
              return next();
            }
            next("/report-researchs");
          },
        },
        {
          path: "/report-researchs-edit/:id",
          name: "แก้ไขรายงานขอเบิกเงินโครงการวิจัย",
          component: ReportResearchsEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-researchs") {
              return next();
            }
            next("/report-researchs");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/report-publications",
    component: Admin,
    children: [
        {
          path: "/report-publications",
          name: "หนังสือมอบอำนาจ",
          component: ReportPublicationsList,
          beforeEnter: commonGuard,
        },
        {
          path: "/report-publications-add",
          name: "รายงานหนังสือมอบอำนาจ",
          component: ReportPublicationsAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-publications") {
              return next();
            }
            next("/report-publications");
          },
        },
        {
          path: "/report-publications-edit/:id",
          name: "แก้ไขรายงานหนังสือมอบอำนาจ",
          component: ReportPublicationsEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-publications") {
              return next();
            }
            next("/report-publications");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/report-authorizes",
    component: Admin,
    children: [
        {
          path: "/report-authorizes",
          name: "หนังสือเชิญเข้าร่วม/ประชุม",
          component: ReportAuthorizesList,
          beforeEnter: commonGuard,
        },
        {
          path: "/report-authorizes-add",
          name: "รายงานหนังสือเชิญเข้าร่วม/ประชุม",
          component: ReportAuthorizesAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-authorizes") {
              return next();
            }
            next("/report-authorizes");
          },
        },
        {
          path: "/report-authorizes-edit/:id",
          name: "แก้ไขรายงานหนังสือเชิญเข้าร่วม/ประชุม",
          component: ReportAuthorizesEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-authorizes") {
              return next();
            }
            next("/report-authorizes");
          },
        },
     
      
    ],
  },
  {
    path: "/",
    redirect: "/report-published",
    component: Admin,
    children: [
        {
          path: "/report-published",
          name: "ขอรับค่าตีพิมพ์ผลงาน",
          component: ReportPublishedList,
          beforeEnter: commonGuard,
        },
        {
          path: "/report-published-add",
          name: "รายงานขอรับค่าตีพิมพ์ผลงาน",
          component: ReportPublishedAdd,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-published") {
              return next();
            }
            next("/report-published");
          },
        },
        {
          path: "/report-published-edit/:id",
          name: "แก้ไขขอรับค่าตีพิมพ์ผลงาน",
          component: ReportPublishedEdit,
          beforeEnter: (to, from, next) => {
            if (from.path == "/report-published") {
              return next();
            }
            next("/report-published");
          },
        },
     
      
    ],
  },



  // {
  //   path: '/report-1',
  //   name: 'ขอเบิกเงินโครงการวิจัย',
  //   component: Report1,
  //   children: [
  //     {
  //       path: "/setting-sign-position",
  //       name: 'ขอเบิกเงินโครงการวิจัย',
  //       component: Report1,
  //     },
  //   ],
  // },
  // {
  //   path: '/report-2',
  //   name: 'ขอรับค่าตีพิมพ์ผลงาน',
  //   component: Report2,
  //   children: [
  //     {
  //       path: "/report-2",
  //       name: 'ขอรับค่าตีพิมพ์ผลงาน',
  //       component: Report2,
  //     },
  //   ],
  // },
  // {
  //   path: '/report-3',
  //   name: 'หนังสือเชิญประชุม',
  //   component: Report3,
  //   children: [
  //     {
  //       path: "/report-3",
  //       name: 'หนังสือเชิญประชุม',
  //       component: Report3,
  //     },
  //   ],
  // },
  // {
  //   path: '/report-4',
  //   name: 'หนังสือมอบอำนาจ',
  //   component: Report4,
  //   children: [
  //     {
  //       path: "/report-4",
  //       name: 'หนังสือมอบอำนาจ',
  //       component: Report4,
  //     },
  //   ],
  // },
  {
    path: '/approve-wait',
    name: 'รอการพิจารณา/อนุมัติ',
    component: ApproveWait,
    children: [
      {
        path: "/approve-wait",
        name: 'รอการพิจารณา/อนุมัติ',
        component: ApproveWait,
      },
    ],
  },
  {
    path: '/approve-reject',
    name: 'ถูกตีกลับ-ให้แก้ไข',
    component: ApproveReject,
    children: [
      {
        path: "/approve-reject",
        name: 'ถูกตีกลับ-ให้แก้ไข',
        component: ApproveReject,
      },
    ],
  },
  {
    path: '/approve-done',
    name: 'เสร็จสิ้น/อนุมัติ',
    component: ApproveDone,
    children: [
      {
        path: "/approve-done",
        name: 'เสร็จสิ้น/อนุมัติ',
        component: ApproveDone,
      },
    ],
  },
];

export default routes;
