import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// ant design
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";

// sweetalert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// loading
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

// styles
import "@/assets/styles/index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// store
import store from "./store/store";
// routes
import routes from "./routers";

import App from "@/App.vue";

import dayjs from 'dayjs';
import th from 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';


const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

app.use(router).use(Antd).use(store).use(VueSweetalert2).use(LoadingPlugin, {
  color: "#F06599",
});

dayjs.locale(th);
dayjs.extend(buddhistEra);

const currentYearBE = dayjs().year() + 543;

app.config.globalProperties.$dayjs = dayjs;
app.mount("#app");