<template>
  <div
    class="w-full bg-white p-4 boxShadow-main"
    :style="{ borderRadius: '10px' }"
  >
    <a-row :gutter="[16]">
      <a-col :lg="18">
        <a-row :gutter="[16, 16]">
         
          <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
            <RangePickkerUCVue
              :onChanged="changedDate"
              :startedDate="searchValues.letter_project_begin"
              :endedDate="searchValues.letter_project_end"
            />
          </a-col>
          <!-- <a-col :lg="6" :md="12" :xs="12" class="gutter-row">
            <TreeSelectSource
              label="ประเภทหนังสือเชิญ"
              placeholder="ระบุประเภทหนังสือเชิญ"
              :textValues="searchValues.letter_source_id"
              :onChanged="(v) => (this.searchValues.letter_source_id = v)"
              :optionValue="searchValues.letter_source_id"
              :options="treeData"
              :v-model="letter_source_id"
            />
          </a-col> -->
          <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
            <AutoCompleteUC
              label="ชื่องานวิจัย"
              placeholder="ระบุชื่องานวิจัย"
              :options="researchNameOptions"
              :onChanged="(v) => (searchValues.letter_project_topic = v)"
              :optionValue="searchValues.letter_project_topic"
            />
          </a-col>
          <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
            <AutoCompleteUC
              v-if="$store.state.user.role == 'supporter'"
              label="หัวหน้าโครงการ"
              placeholder="ชื่อหัวหน้าโครงการวิจัย"
              :options="researchLeadOptions"
              :onChanged="(v) => (searchValues.research_name = v)"
              :optionValue="searchValues.research_name"
            />
          </a-col>
        </a-row>
      </a-col>

      <a-col :lg="6" :md="0" :xs="0" class="gutter-row">
        <a-row :gutter="[16, 16]" class="h-full">
          <a-col :md="12">
            <ButtonUCVue
              :header="'test'"
              :label="'ค้นหา'"
              :isBg="true"
              :onClicked="() => onClickedSearch()"
              :iconName="'searchIcon'"
              :iconColor="'#FFF'"
            />
          </a-col>
          <a-col :md="12">
            <ButtonUCVue
              :header="'header'"
              :label="'ล้างค่า'"
              :onClicked="() => handleClearSearch()"
              :iconName="'refreshIcon'"
              :iconColor="'#ED4081'"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col :lg="0" :md="24" :xs="24" class="gutter-row">
        <a-row :gutter="[16, 16]" class="h-full">
          <a-col :md="12">
            <ButtonUCVue
              :label="'ค้นหา'"
              :isBg="true"
              :onClicked="() => onClickedSearch()"
              :iconName="'searchIcon'"
              :iconColor="'#FFF'"
            />
          </a-col>
          <a-col :md="12">
            <ButtonUCVue
              :label="'ล้างค่า'"
              :onClicked="() => handleClearSearch()"
              :iconName="'refreshIcon'"
              :iconColor="'#ED4081'"
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ButtonUCVue from "../ButtonUC.vue";
import AutoCompleteUC from "../AutoCompleteUC.vue";
// import TreeSelectSource from "../TreeSelectSource.vue";
import RangePickkerUCVue from "../RangePickkerUC.vue";
import dayjs from "dayjs";
import DateFunction from "../../services/DateFunction";

export default {
  components: {
    ButtonUCVue,
    AutoCompleteUC,
    RangePickkerUCVue,
    // TreeSelectSource,
  },

  props: {
    onChanged: {
      Type: Function,
    },
    onClickedSearch: {
      Type: Function,
    },
    researchNameOptions: {
      Type: Array,
      default: () => [],
    },
    researchLeadOptions: {
      Type: Array,
      default: () => [],
    },
    treeData: {
      Type: Array,
      default: () => [],
    },
  },

  watch: {
    searchValues(newValue) {
      this.onChanged(newValue);
    },
  },

  mounted() {
    this.onChanged(this.searchValues);
  },

  methods: {
    handleSearch() {
      // console.log(this.searchValues);
    },
    changedDate(date) {
      this.searchValues = {
        ...this.searchValues,
        letter_project_begin: date.startDate,
        letter_project_end: date.endDate,
        
      };
    },
    handleClearSearch() {
      this.searchValues = {
        letter_source_id: "",
        letter_project_begin: dayjs().startOf("year"),
        letter_project_end: dayjs().endOf("year"),
        letter_project_topic: "",
        research_name: "",
      };
      this.onClickedSearch();
    },
  },

  data() {
    return {
      research_name: null,
      letter_project_topic:null,
      searchValues: {
        letter_source_id: "",
        letter_project_begin: dayjs()
          .startOf("year")
          // .add(543, "year")
          .subtract(1, "year"),
        letter_project_end: dayjs().endOf("year"),
        // .add(543, "year"),
        letter_project_topic: "",
        research_name: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
