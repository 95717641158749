<template>
    <div class="flex flex-col gap-4">
  
      <div
        class="p-4 rounded-main boxShadow-main flex justify-between items-center"
        :style="{ backgroundColor: '#FFF' }"
        v-if="isHeader"
      >

        <p class="text-xs">
          {{
            `หน้าที่ ${paginationConfig.current} / ${
              paginationConfig.current * paginationConfig.pageSize -
              paginationConfig.pageSize +
              1
            } - ${paginationConfig.current * paginationConfig.pageSize} จาก ${
              paginationConfig.total
            } รายการ`
          }}
        </p>
       
      </div>
  
      <div
        class="rounded-main boxShadow-main"
        :style="{ backgroundColor: '#FFF', overflow: 'hidden' }"
      >
        <a-table
          :columns="columnsList"
          :data-source="numberedData"
          :pagination="paginationConfig"
          :locale="{ emptyText: 'ไม่พบข้อมูล' }"
          class="custom-table"
          :customRow="customRow"
          :row-class-name="(_record, index) => getRowClassName(_record)"
        >
            <template #bodyCell="{ column, record }">
            
              <template
            v-if="
              column.dataIndex === 'account' 
            "
            >
            <div>
              {{
                column.dataIndex === "account"? (record.approve_by) +"\n"
                : ""
              }}
            </div>
          </template>

            <template v-if="column.dataIndex === 'manage_data'">
              
              <button>
                <BaseIconVue :style="{
                  borderRadius: '40px',
                  backgroundColor: isBg ? '#419b6e' : '#419b6e',
                  color: isBg ? '#FFF' : '#FFF',
                  padding: '6px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }"
                  name="LockAppIcon"
                  @click.stop="() => EditClicked(record)"
                />
                {{
                  record.is_active_reject == "1"?  "ตีกลับ" : "ไม่ตีกลับ"
                }}
                
              </button>

              
           
            </template>
  
            
          </template>
        </a-table>
      </div>
    </div>
  </template>
  
  <script>
  import { reactive } from "vue";
  import BaseIconVue from "./Icon/BaseIcon.vue";
  import DateFunction from "../services/DateFunction";
  
  export default {
    components: {
      BaseIconVue,
    },
  
    props: {
      isHeader: {
        Type: Boolean,
        default: true,
      },
      // isFilterType: {
      //   Type: Boolean,
      //   default: true,
      // },
      columnsList: {
        Type: String,
      },
      tableData: {
        Type: String,
      },
      onClickedRows: {
        Type: Function,
      },
      dataLength: {
        Type: Number,
        default: 0,
      },
      paginationConfig: {
        Type: String,
      },
      filterTable: {
        Type: String,
      },
      sortBy: {
        Type: Number,
      },
      sortChanged: {
        Type: Function,
      },
      statusChanged: {
        Type: Function,
      },
      
      fileDownload: {
        Type: Function,
        required: false,
      },
      EditClicked: {
        Type: Function,
        required: false,
      },
      DeleteClicked: {
        Type: Function,
        required: false,
      },
      
      tableStrip: {
        Type: Boolean,
        default: false,
      },
    },
  
    mounted() {
      this.valueSort = this.sortBy;
    },
  
    computed: {
      numberedData() {
        return this.tableData.map((item, index) => {
          return {
            ...item,
            rowNumber: index + 1,
          };
        });
      },
    },
  
    methods: {
      approveStatus(status, index) {
        switch (status) {
          case "funding":
            return "สัญญารับทุน";
          case "progress":
            return `รายงานความคืบหน้า ${index}`;
          case "complete":
            return "รายงานฉบับสมบูรณ์";
          case "ohc":
            return "นำส่ง OHC";
          case "edit":
            return "รายงานฉบับแก้ไข";
        }
      },
  
      changeValues(value) {
        this.sortChanged(value);
        this.valueSort = value;
      },
  
      changeTab(tab) {
        this.statusChanged(tab);
        this.activeValue = tab;
      },
  
      customRow(record) {
        return {
          onClick: () => {
            if (this.onClickedRows) {
              this.onClickedRows(record);
            }
          },
        };
      },
  
      formatDate(date) {
        if (date) {
          return DateFunction.formatDate(date);
        } else {
          return "-";
        }
      },
  
      getRowClassName(record, index) {
        if (record.manage_data == "edit" && this.tableStrip) {
          return "table-edit";
        } else if (record.manage_data == "delete" && this.tableStrip) {
          return "table-delete";
        }
      },
    },
  
    data() {
      return {
        valueSort: 0,
        activeValue: "",
        editableData: reactive({}),
      };
    },
  };
  </script>
  
  <style scoped>
  .button-tap {
    border: 0;
    padding: 8px 16px;
    border-radius: 40px;
    height: 52px;
    color: #f39dbe;
    box-shadow: none !important;
  }
  
  .button-tap:hover {
    color: rgb(237 64 129 / 100%);
  }
  
  .button-tap:focus {
    outline: 0 !important;
  }
  
  .active {
    background-color: #f7d5e2;
    color: #ed4081;
  }
  </style>
  
  