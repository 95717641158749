<template>
    <a-layout>
      <sidebar />
      <div
        class="relative"
        :style="{ background: '#f5f5f5' }"
        :class="{
          'md:ml-64': $store.state.sideBar,
          'transition-all duration-200 ease-in-out': true,
        }"
      >
        <admin-navbar />
        <div class="px-4 md:px-6 mx-auto w-ful pb-6 pt-24 min-h-screen h-full">
          <Breadcrumb />

            <div class="px-4 py-5 flex-auto">
                  <h6 class="text-xl font-semibold">สถานะหนังสือ/บันทึก : ถูกตีกลับ-ให้แก้ไข</h6>
                  <!-- <p class="mt-2 mb-4 text-blueGray-500">
                    Divide details about your product or agency work into parts.
                    A paragraph describing a feature will be enough.
                  </p> -->
                </div>


        </div>
      </div>
    </a-layout>
  </template>
  <script>
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
  import Sidebar from "@/components/Sidebar/Sidebar.vue";
  
  export default {
    name: "ApproveReject",
    components: {
      Breadcrumb,
      AdminNavbar,
      Sidebar,
    },
  
    data() {
      return {};
    },
  };
  </script>
  
  <style scoped></style>