<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 102.55 122.88"
    fill="none"
  >
    <path
      d="M102.55,122.88H0V0h77.66l24.89,26.43V122.88L102.55,122.88z M64.76,58.39h4.66l-5.38,23.86L57.05,48.7H45.81 l-8.14,33.55l-6.1-33.55H19.82l11.93,54h11.49l8.05-31.94l7.2,31.94h10.59l13.67-54H64.76V58.39L64.76,58.39z M96.13,115.98V30.44 H73.44V5.91H6.51v110.07H96.13L96.13,115.98z"
      fill="#4691f6"
    />
  </svg>
</template>

