<template>
  <div
    class="w-full flex justify-center items-center flex-col"
    :style="{ height: '100vh' }"
  >
    <div
      class="px-4 py-8 md:w-96"
      :style="{
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.15)',
        backdropFilter: 'blur(2px)',
        border: '1px solid #B8B9BA',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }"
    >
      <div>
        <img src="../assets/EconLogo.png" alt="logo" />
      </div>
      <h1 class="mt-8" :style="{ fontSize: '1.25rem' }">เข้าสู่ระบบ</h1>
      <div class="mt-4 w-full flex flex-col gap-4" @keyup.enter="handleLogin">
        <TextInputUCVue
          :label="'ชื่อผู้ใช้'"
          :isRequire="true"
          :name="'email'"
          :id="'email'"
          placeholder="ระบุชื่อผู้ใช้"
          :textValues="userLogin.email"
          :prefix="'M15.5635 3.83054C14.6822 2.87899 13.4512 2.35498 12.0925 2.35498C10.7265 2.35498 9.49148 2.87582 8.61421 3.82148C7.72743 4.77755 7.29536 6.07693 7.39681 7.48002C7.5979 10.2482 9.70434 12.5 12.0925 12.5C14.4806 12.5 16.5835 10.2486 16.7877 7.48092C16.8905 6.09052 16.4558 4.79386 15.5635 3.83054ZM20.0636 22.645H4.12142C3.91275 22.6477 3.7061 22.6039 3.51651 22.5167C3.32691 22.4295 3.15914 22.3011 3.0254 22.1409C2.73101 21.789 2.61235 21.3085 2.70021 20.8225C3.08246 18.702 4.27541 16.9208 6.15042 15.6703C7.81619 14.5602 9.92626 13.9493 12.0925 13.9493C14.2587 13.9493 16.3688 14.5607 18.0346 15.6703C19.9096 16.9203 21.1025 18.7016 21.4848 20.8221C21.5726 21.308 21.454 21.7885 21.1596 22.1405C21.0259 22.3007 20.8581 22.4292 20.6685 22.5165C20.4789 22.6037 20.2723 22.6476 20.0636 22.645Z'"
          :error="error.email"
          :onChanged="(v) => (userLogin.email = v)"
        />
        <TextInputUCVue
          :label="'รหัสผ่าน'"
          :isRequire="true"
          :type="passwordType"
          :name="'password'"
          :id="'password'"
          placeholder="ระบุรหัสผ่าน"
          :textValues="userLogin.password"
          :prefix="'M17.89 8.63541H16.9238V6.70302C16.9238 4.03634 14.7595 1.87207 12.0928 1.87207C9.42612 1.87207 7.26185 4.03634 7.26185 6.70302V8.63541H6.29566C5.23285 8.63541 4.36328 9.50498 4.36328 10.5678V20.2297C4.36328 21.2925 5.23285 22.1621 6.29566 22.1621H17.89C18.9528 22.1621 19.8223 21.2925 19.8223 20.2297V10.5678C19.8223 9.50498 18.9528 8.63541 17.89 8.63541ZM12.0928 17.3311C11.03 17.3311 10.1604 16.4616 10.1604 15.3987C10.1604 14.3359 11.03 13.4664 12.0928 13.4664C13.1556 13.4664 14.0252 14.3359 14.0252 15.3987C14.0252 16.4616 13.1556 17.3311 12.0928 17.3311ZM9.19424 8.63541V6.70302C9.19424 5.09915 10.4889 3.80445 12.0928 3.80445C13.6967 3.80445 14.9914 5.09915 14.9914 6.70302V8.63541H9.19424Z'"
          :suffix="'M12.5 9.77279C11.7767 9.77279 11.083 10.0601 10.5715 10.5716C10.0601 11.0831 9.77273 11.7767 9.77273 12.5001C9.77273 13.2234 10.0601 13.9171 10.5715 14.4285C11.083 14.94 11.7767 15.2273 12.5 15.2273C13.2233 15.2273 13.917 14.94 14.4285 14.4285C14.9399 13.9171 15.2273 13.2234 15.2273 12.5001C15.2273 11.7767 14.9399 11.0831 14.4285 10.5716C13.917 10.0601 13.2233 9.77279 12.5 9.77279ZM12.5 17.0455C11.2945 17.0455 10.1383 16.5666 9.28588 15.7142C8.43344 14.8618 7.95455 13.7056 7.95455 12.5001C7.95455 11.2945 8.43344 10.1384 9.28588 9.28594C10.1383 8.43351 11.2945 7.95461 12.5 7.95461C13.7055 7.95461 14.8617 8.43351 15.7141 9.28594C16.5666 10.1384 17.0455 11.2945 17.0455 12.5001C17.0455 13.7056 16.5666 14.8618 15.7141 15.7142C14.8617 16.5666 13.7055 17.0455 12.5 17.0455ZM12.5 5.68188C7.95455 5.68188 4.07273 8.50916 2.5 12.5001C4.07273 16.491 7.95455 19.3182 12.5 19.3182C17.0455 19.3182 20.9273 16.491 22.5 12.5001C20.9273 8.50916 17.0455 5.68188 12.5 5.68188Z'"
          :onClicked="() => showPassword()"
          :error="error.password"
          :onChanged="(v) => (userLogin.password = v)"
        />
        <div class="flex items-center justify-between">
          <div>
            <input
              type="checkbox"
              name="rememberme"
              id="rememberme"
              class="mr-2"
              v-model="checkMe"
            />
            <label for="rememberme" :style="{ fontSize: '0.875rem' }"
              >จดจำฉัน</label
            >
          </div>
          <a href="https://apps.econ.cmu.ac.th/passport" style="font-size: 14px;" >สมัครเข้าใช้งานระบบ</a>
        </div>
        <!-- <div class="flex items-center" style="align-items: center;justify-content: center;">
          <a href="https://www.econ.cmu.ac.th/passport/" target="_blank">สมัครเข้าใช้งานระบบ</a>
        </div> -->
        <ButtonUCVue
          :label="'เข้าสู่ระบบ'"
          :isBg="true"
          :onClicked="() => handleLogin()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonUCVue from "../components/ButtonUC.vue";
import TextInputUCVue from "../components/TextInputUC.vue";
import { HTTP } from "../http-common";
import EncyptServices from "../services/EncryptServices";

export default {
  components: {
    TextInputUCVue,
    ButtonUCVue,
  },

  mounted() {
    const rememberUser = localStorage.getItem("rememberUser");
    if (rememberUser) {
      const user = JSON.parse(EncyptServices.decrypt(rememberUser));
      if (user.email && user.password) {
        this.userLogin.email = user.email;
        this.userLogin.password = user.password;
        this.checkMe = true;
      }
    }
  },

  data() {
    return {
      userLogin: {
        email: "",
        password: "",
      },
      error: {},
      checkMe: false,
      passwordType: "password",
    };
  },
  methods: {
    showPassword() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },

    validate() {
      let error = {};
      if (this.userLogin.email == "") {
        error.email = "กรุณาระบุอีเมล์";
      }

      if (this.userLogin.password == "") {
        error.password = "กรุณาระบุรหัสผ่าน";
      }

      this.error = error;
      return Object.keys(error) == 0;
    },

    async handleLogin() {
      if (this.validate()) {
        let loader = this.$loading.show();

        if (this.checkMe) {
          const userItem = EncyptServices.encrypt(
            JSON.stringify(this.userLogin)
          );
          localStorage.setItem("rememberUser", userItem);
          localStorage.setItem("checkMe", this.checkMe);
        } else {
          localStorage.removeItem("rememberUser");
          localStorage.removeItem("checkMe");
        }

        const { data } = await HTTP.post("/login", {
          username: this.userLogin.email,
          password: this.userLogin.password,
        });
        if (data.statusCode == 200) {
          localStorage.setItem(
            "user",
            EncyptServices.encrypt(
              JSON.stringify({ ...data.user, token: data.token })
            )
          );

          this.$store.commit("setUser", {
            ...data.user,
            token: data.token,
          });

          loader.hide();
          this.$router.push("/research-list");
        } else if (data.statusCode == 403) {
          this.error = {
            email: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
          };
        }
        loader.hide();
      }
    },
  },
};
</script>
