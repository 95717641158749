<template>
  <div
    class="w-full bg-white p-4 boxShadow-main"
    :style="{ borderRadius: '10px' }"
  >
    <a-row :gutter="[16]">
      <a-col :lg="18">
        <a-row :gutter="[16, 16]">
          <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
            <TextInputUCVue
              :label="'ค้นหา'"
              :placeholder="'ระบุชื่อเรื่องที่ต้องการค้นหา'"
              :textValues="searchValues.global"
              :onChanged="(v) => (this.searchValues.global = v)"
            />
            <p :style="{ opacity: 0.5 }">Keyword ชื่อบทความ...</p>
          </a-col>
          <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
            <AutoCompleteUC
              v-if="$store.state.user.role == 'supporter'"
              label="ชื่อผู้จัดทำ"
              placeholder="ระบุชื่อผู้จัดทำ"
              :options="researchLeadOptions"
              :onChanged="(v) => (searchValues.research_name = v)"
              :optionValue="searchValues.research_name"
            />
          </a-col>
        </a-row>
      </a-col>
      

      <a-col :lg="6" :md="0" :xs="0" class="gutter-row">
        <a-row :gutter="[16, 16]" class="h-full">
          <a-col :md="12">
            <ButtonUCVue
              :header="'test'"
              :label="'ค้นหา'"
              :isBg="true"
              :onClicked="() => onClickedSearch()"
              :iconName="'searchIcon'"
              :iconColor="'#FFF'"
            />
          </a-col>
          <a-col :md="12">
            <ButtonUCVue
              :header="'header'"
              :label="'ล้างค่า'"
              :onClicked="() => handleClearSearch()"
              :iconName="'refreshIcon'"
              :iconColor="'#ED4081'"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col :lg="0" :md="24" :xs="24" class="gutter-row">
        <a-row :gutter="[16, 16]" class="h-full">
          <a-col :md="12">
            <ButtonUCVue
              :label="'ค้นหา'"
              :isBg="true"
              :onClicked="() => onClickedSearch()"
              :iconName="'searchIcon'"
              :iconColor="'#FFF'"
            />
          </a-col>
          <a-col :md="12">
            <ButtonUCVue
              :label="'ล้างค่า'"
              :onClicked="() => handleClearSearch()"
              :iconName="'refreshIcon'"
              :iconColor="'#ED4081'"
            />
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ButtonUCVue from "../ButtonUC.vue";
import TextInputUCVue from "../TextInputUC.vue";
import AutoCompleteUC from "../AutoCompleteUC.vue";
import dayjs from "dayjs";
import DateFunction from "../../services/DateFunction";

export default {
  components: {
    TextInputUCVue,
    ButtonUCVue,
    AutoCompleteUC,
 
  },

  props: {
    onChanged: {
      Type: Function,
    },
    onClickedSearch: {
      Type: Function,
    },
    researchNameOptions: {
      Type: Array,
      default: () => [],
    },
    researchLeadOptions: {
      Type: Array,
      default: () => [],
    },
  },

  watch: {
    searchValues(newValue) {
      this.onChanged(newValue);
    },
  },

  mounted() {
    this.onChanged(this.searchValues);
  },

  methods: {
    handleSearch() {
      // console.log(this.searchValues);
    },
    changedDate(date) {
      this.searchValues = {
        ...this.searchValues,
      };
    },
    handleClearSearch() {
      this.searchValues = {
        global: "",
        research_name: "",
       
      };
      this.onClickedSearch();
    },
  },

  data() {
    return {
      research_name: null,
      searchValues: {
        global: "",
       
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
