import numeral from 'numeral';

const NumberFunction = {
//   formatNumber(number) {
//     return numeral(number).format('0,0'); // Formats as "1,234,567"
//   },
//   formatCurrency(value) {
//     return numeral(value).format('$0,0.00'); // Formats as "$1,234,567.00"
//   }
// formatNumber(number) {
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// }
  

};

export default NumberFunction;
