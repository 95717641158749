<template>
    <div class="flex flex-wrap">
        <div class="w-full bg-white p-4 boxShadow-main" :style="{ borderRadius: '10px' }">
            <form @submit.prevent="submitForm">
            <a-row :gutter="[16]">
                <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                    <a-row :gutter="[16, 16]">
                        <a-col :lg="24" :md="24" :xs="24">
                             <TextInputUCVue  :v-model="name" :label="'ชื่อตำแหน่ง'"
                                :placeholder="'ระบุชื่อตำแหน่ง'" :textValues="ReportSignData?.name"
                                :onChanged="(v) => (this.Values.name = v)" />
                        </a-col>
                    </a-row>
                </a-col>

            </a-row>


            <a-row :gutter="[16]">
                <a-col :lg="8" :md="12" :xs="24" class="gutter-row">
                    <a-row :gutter="[16, 16]" class="h-full">
                        <a-col :md="12">
                            <ButtonUCVue  type="submit" :header="'test'" :label="'บันทึกข้อมูล'" :isBg="true"
                                 :iconName="'SaveIcon'" :iconColor="'#FFF'" />
                        </a-col>
                        <!-- <a-col :md="12">
                            <ButtonUCVue header="'header'" :label="'ล้างค่า'" :onClicked="() => handleClear()"
                                :iconName="'refreshIcon'" :iconColor="'#ED4081'" />
                        </a-col> -->
                    </a-row>
                </a-col>
            </a-row>
            </form>
        </div>
        <!-- Button Actions -->
        <div
        class="w-full bg-white mt-2 p-4 boxShadow-main flex gap-4 items-center justify-end sticky bottom-0"
        :style="{ borderRadius: '10px', height: '100%' }"
        >
        <div :style="{ minWidth: '10rem' }">
          <ButtonUCVue label="ย้อนกลับ" :onClicked="backPage" />
        </div>
        </div>
    </div>
</template>
<script>
import ButtonUCVue from "../../components/ButtonUC.vue";
import TextInputUCVue from "../../components/TextInputUC.vue";
import { HTTP } from "../../http-common";


export default {
    name: "ReportSignPositionEdit",
    components: {
        TextInputUCVue,
        ButtonUCVue,

    },
    mounted() {
        
        if (this.$router.currentRoute._rawValue.params.id) {
            this.id = this.$router.currentRoute._rawValue.params.id;
            this.getPositions(this.id);
        }
        this.$store.commit("setChild", this.$router.currentRoute._value);
    },

    
    // mounted() {
    //     if (this.$router.currentRoute._rawValue.params.project_id) {
    //     this.project_id = this.$router.currentRoute._rawValue.params.project_id;
    //     this.getResearchById(this.project_id);
    //     }
    //     this.$store.commit("setChild", this.$router.currentRoute._value);
    // },

    unmounted() {
        //this.$store.commit("setClearFileSave");
        this.$store.commit("setChild", "");
    },

    methods: {
        async getPositions(id) {
            let loader = this.$loading.show(); 
            try {
            const { data } = await HTTP.get(`/getPositions/${id}`);
            
            if (data.data && data.data.length > 0) {
                this.Values = data?.data[0]; /* Fetch to Values */
                this.ReportSignData = data?.data[0];
                loader.hide();
            } else {
                this.ReportSignData = null;
                loader.hide();
            }
            } catch (error) {
            console.error(error.message);
            
            }
        },
  
        async backPage() {
            this.$router.push(`/setting-sign-position`);
        },
        async handleClear() {
            this.ReportSignData = {
                name: "",
            };
           
        },
        async submitForm() {
            try {
                const body = {
                    id: this.ReportSignData?.id,
                    name: this.Values.name,
                }
                
                const { data } = await HTTP.post("/UpdatePositions", body);
                // alert(this.Values.name);
                if (data.status) {
                    this.$swal.fire({
                        title: "บันทึกสำเร็จ",
                        width: 300,
                        height: 300,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                    }).then((result) => {
                        this.$router.push(`/setting-sign-position`);
                    });
                    
                }
            } catch (error) {
                console.error('There was an error!', error);
            }
           
        },

    },

    data() {
        return {
            loader: "",
            ReportSignData: null,
            Values: {
                name: "",
            },
        };
    },



};
</script>

<style scoped></style>