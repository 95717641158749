<template>
    <div class="flex flex-wrap">
      <div class="w-full flex flex-col gap-4">
            <TableReportApprovers
                :columnsList="columns"
                :tableData="dataValues"
                :EditClicked="onClickEdit"
                :paginationConfig="paginationConfig"
                :filterTable="filterTable"
                :sortBy="sort_by"
                :sortChanged="
                  (v) => {
                    sort_by = v;
                    fetchReportApproversList();
                  }
                "
              />
        </div>
      </div>
    </template>
    <script>
    import TableReportApprovers from "../../components/TableReportApprovers.vue"; 
    import { HTTP } from "../../http-common";
    import DateFunction from "../../services/DateFunction";
    
    export default {
      name: "ReportSignList",
      components: {
        TableReportApprovers,
    
       
      },
      mounted() {
        this.fetchReportApproversList();
        this.$store.commit("setBread", this.$router.currentRoute._value);
      },
      methods: {
        async fetchReportApproversList() {
          let loading = this.$loading.show();
  
          let body = {
            limit: this.paginationConfig.pageSize,
            offset:
              this.paginationConfig.pageSize * (this.paginationConfig.current - 1),
            
          };
  
          const { data } = await HTTP.post("/getReportApproversList", body);
          
  
          if (data.data && data.data.length > 0) {
            this.dataValues = data.data;
            this.paginationConfig.total = data.total;
  
            loading.hide();
          } else {
            loading.hide();
            this.dataValues = [];
            this.paginationConfig.total = 0;
          }
          loading.hide();
        },
  
        // onClickAdd() {
        //   this.$router.push(`/setting-sign-position-add`);
        // },
        onClickEdit(data) {
          this.$router.push(`/setting-approvers-edit/${data.id}`);
        },
        
      
        handlePageChange(page) {
          this.paginationConfig = {
            ...this.paginationConfig,
            current: page,
          };
          this.fetchReportApproversList();
        },
  
        handlePageSizeChange(current, pageSize) {
          this.paginationConfig = {
            ...this.paginationConfig,
            current: current,
            pageSize: pageSize,
          };
        },
      },
  
      data() {
        return {
          loader: "",
          search_value: null,
          sortOptions: 0,
  
          columns: [
            {
              title: "ตำแหน่งงาน",
              dataIndex: "name",
              width: 250,
            },
            {
              title: "บทบาทพิจารณา/ผู้อนุมัติ",
              dataIndex: "approve_step",
              width: 250,
            },
            {
              title: "CMU IT ACCOUNT",
              dataIndex: "account",
              width: 250,
            },
            {
              title: "สถานะ (ตีกลับ)",
              dataIndex: "manage_data",
              align: "center",
              width: 150,
            },
          ],
       
          paginationConfig: {
            total: 0,
            pageSize: 10,
            current: 1,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 50, 100],
            onChange: this.handlePageChange,
            onShowSizeChange: this.handlePageSizeChange,
          },
  
          dataValues: [],
          name: [],
          dataLength: 0,
        };
      },
      
  
      
    };
    </script>
    
    <style scoped></style>