<template>
    <div class="flex flex-wrap">
        <div class="w-full bg-white p-4 boxShadow-main" :style="{ borderRadius: '10px' }">
            <form @submit.prevent="submitForm">
                <a-row :gutter="[16]">
                    <a-col :lg="24" :md="24" :xs="24" class="gutter-row">

                        <a-row :gutter="[16, 16]">

                            <a-col :lg="20" :md="20" :xs="24">
                                <TextInputUCVue :v-model="approve_by" name="approve_by"
                                    :label="'ผู้อนุมัติ (สามารถเลือกรายชื่อ ได้มากกว่า 1 คน)'"
                                    :placeholder="'ผู้อนุมัติ (สามารถเลือกรายชื่อ ได้มากกว่า 1 คน)'"
                                    :textValues="Values.approve_by" :onChanged="(v) => (this.Values.approve_by = v)" />
                            </a-col>
                            <a-col :lg="4" :md="4" :xs="24">
                                <a-col :lg="24" :md="24" :xs="24">สถานะตีกลับ</a-col>
                                <label class="ml-2">
                                    <input type="radio" name="is_active_reject" :v-model="is_active_reject" value="0"
                                        @change="handleRadioChange($event)" :checked="Values.is_active_reject == '0'" />
                                    ไม่ตีกลับ
                                </label><br>
                                <label class="ml-2">
                                    <input type="radio" name="is_active_reject" :v-model="is_active_reject" value="1"
                                        @change="handleRadioChange($event)" :checked="Values.is_active_reject == '1'" />
                                    ตีกลับ
                                </label>
                            </a-col>



                        </a-row>
                    </a-col>

                </a-row>




                <a-row :gutter="[16]">
                    <a-col :lg="8" :md="12" :xs="24" class="gutter-row">
                        <a-row :gutter="[16, 16]" class="h-full">
                            <a-col :md="12">
                                <ButtonUCVue type="submit" :header="'test'" :label="'บันทึกข้อมูล'" :isBg="true"
                                    :iconName="'SaveIcon'" :iconColor="'#FFF'" />
                            </a-col>
                            <!-- <a-col :md="12">
                                <ButtonUCVue header="'header'" :label="'ล้างค่า'" :onClicked="() => handleClear()"
                                    :iconName="'refreshIcon'" :iconColor="'#ED4081'" />
                            </a-col> -->
                        </a-row>
                    </a-col>
                </a-row>
            </form>
        </div>
        <!-- Button Actions -->
        <div class="w-full bg-white mt-2 p-4 boxShadow-main flex gap-4 items-center justify-end sticky bottom-0"
            :style="{ borderRadius: '10px', height: '100%' }">
            <div :style="{ minWidth: '10rem' }">
                <ButtonUCVue label="ย้อนกลับ" :onClicked="backPage" />
            </div>
        </div>
        <!-- <button @click="checkFormData">Check</button> -->
    </div>
</template>
<script>
import ButtonUCVue from "../../components/ButtonUC.vue";
import TextInputUCVue from "../../components/TextInputUC.vue";
import { HTTP } from "../../http-common";
import dayjs from "dayjs";
import moment from "moment";


export default {
    name: "ReportSignPositionEdit",
    components: {
        TextInputUCVue,
        ButtonUCVue,


    },
    mounted() {

        if (this.$router.currentRoute._rawValue.params.id) {
            this.id = this.$router.currentRoute._rawValue.params.id;
            this.getReportApprovers(this.id);
        }
        this.$store.commit("setChild", this.$router.currentRoute._value);
    },


    // mounted() {
    //     if (this.$router.currentRoute._rawValue.params.project_id) {
    //     this.project_id = this.$router.currentRoute._rawValue.params.project_id;
    //     this.getResearchById(this.project_id);
    //     }
    //     this.$store.commit("setChild", this.$router.currentRoute._value);
    // },

    unmounted() {
        //this.$store.commit("setClearFileSave");
        this.$store.commit("setChild", "");
    },

    methods: {
        async checkFormData() {
            // console.log(this.ReportData);
            console.log(this.Values);
        },
        handleRadioChange(event) {
            // จัดการการเปลี่ยนแปลงของ radio button
            this.is_active_reject = event.target.value;
            //alert(`Radio button selected: ${this.is_active_reject}`);

        },

        async getReportApprovers(id) {
            let loader = this.$loading.show();
            try {
                const { data } = await HTTP.get(`/getReportApprovers/${id}`);

                if (data.data && data.data.length > 0) {
                    this.Values = data?.data[0]; /* Fetch to Values */

                    // Radio
                    // alert(dconvert_letter_date);
                    this.Values.approve_by = data.data[0].approve_by;
                    this.Values.is_active_reject = data.data[0].is_active_reject;


                    this.ReportData = data?.data[0];
                    loader.hide();
                } else {
                    this.ReportData = null;
                    loader.hide();
                }
            } catch (error) {
                console.error(error.message);

            }
        },

        async backPage() {
            this.$router.push(`/setting-approvers`);
        },
        async handleClear() {
            this.ReportData = {
                approve_by: "",
                is_active_reject: "",


            };



        },
        async submitForm() {


            const formData = new FormData();
            formData.append("id", this.ReportData?.id);
            formData.append("approve_by", this.Values.approve_by);
            formData.append("is_active_reject", this.is_active_reject);


            try {
                await HTTP.post('/UpdateReportApprovers', formData, {
                })
                    .then(response => {
                        this.$swal.fire({
                            title: "บันทึกสำเร็จ",
                            width: 300,
                            height: 300,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                        }).then((result) => {
                            this.$router.push(`/setting-approvers`);
                        });
                    })
                    .catch(error => {
                        console.error('Error uploading file:' + error);
                    });

            } catch (error) {
                console.error('There was an error!', error);
            }






        },

    },




    data() {
        return {
            ReportData: null,
            is_active_reject: "",
            loader: "",
            Values: {
                approve_by: "",

            },
        };
    },



};
</script>

<style scoped>
.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border: 2px solid #4CAF50;
    border-radius: 50px;
    border-color: #ddd;
    color: ddd;
    font-weight: bold;
    width: 100%;
}

.custom-file-upload input[type="file"] {
    display: none;
}

button {
    margin-top: 10px;
    padding: 6px 12px;
    border: none;
    background-color: #2196F3;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0b7dda;
}
</style>