<template>
<!--    
    <svg xmlns="http://www.w3.org/2000/svg" 
   image-rendering="optimizeQuality" 
    fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512">
    <path 
    d="M256 0c70.68 0 134.69 28.66 181.02 74.98C483.34 121.29 512 185.31 512 256c0 70.69-28.66 134.69-74.98 181.02C390.69 483.34 326.68 512 256 512c-70.69 0-134.71-28.66-181.02-74.98C28.66 390.69 0 326.68 0 256c0-70.69 28.66-134.69 74.98-181.02C121.29 28.66 185.31 0 256 0zm122.47 256c0 15.57-12.77 28.31-28.31 28.31h-65.84v65.84c0 15.57-12.76 28.31-28.32 28.31-15.57 0-28.32-12.77-28.32-28.31v-65.84h-65.83c-15.55 0-28.32-12.76-28.32-28.31 0-15.56 12.74-28.32 28.32-28.32h65.83v-65.84c0-15.55 12.77-28.32 28.32-28.32 15.56 0 28.32 12.74 28.32 28.32v65.84h65.84c15.57 0 28.31 12.75 28.31 28.32z"
    fill="white"/></svg> -->


    <!-- <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50">
<path 
d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z"
fill="white"></path>
</svg> -->

<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50">
    <path d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M37,26H26v11h-2V26H13v-2h11V13h2v11h11V26z"
    fill="white"></path>
</svg>
  </template>
  