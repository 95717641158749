import EncyptServices from "../services/EncryptServices";

export function HTTPHeader() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(EncyptServices.decrypt(user));
  }
  if (user && user.token) {
    return {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + user.token,
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
  }
}
