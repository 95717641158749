<template>
    <div class="flex flex-wrap">
        <div class="w-full bg-white p-4 boxShadow-main" :style="{ borderRadius: '10px' }">
            <form @submit.prevent="submitForm">
                <a-row :gutter="[16]">
                    <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                        
                        <a-row :gutter="[16, 16]">
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนหัวของบันทึกข้อความ</b></a-col>
                            <a-col :lg="6" :md="6" :xs="24">
                                <TextInputUCVue :v-model="letter_code" :label="'เลขที่หนังสือ'"
                                    :placeholder="'เลขที่หนังสือ'" :textValues="Values.letter_code"
                                    :onChanged="(v) => (this.Values.letter_code = v)" />
                            </a-col>
                            <a-col :lg="6" :md="6" :xs="24">
                                <DatePickkerUCVue :label="'วันที่หนังสือ'" :dueDateValue="stepDueDate" :onChangeDate="(value) => {
                                    new_step_end_date = true;
                                    stepDueDate = value;
                                }
                                    " />
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>เรียน<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="letter_to"  :placeholder="'เรียน'"
                                    :textValues="Values.letter_to" :onChanged="(v) => (this.Values.letter_to = v)" />
                                    <span v-if="errors.letter_to" class="error-message">{{ errors.letter_to }}</span>
                            </a-col>
                           
                            <a-col :lg="24" :md="24" :xs="24">
                                <br/>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนเนื้อหาของบันทึกข้อความ</b></a-col>

                            <a-col :lg="12" :md="12" :xs="24">
                                <label>ชื่อบทความ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="paper_name" 
                                    :placeholder="'ระบุชื่อบทความ'" :textValues="Values.paper_name" 
                                    :onChanged="(v) => (this.Values.paper_name = v)" />
                                    <span v-if="errors.paper_name" class="error-message">{{ errors.paper_name }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>ชื่อวารสาร<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="paper_journal" 
                                    :placeholder="'ระบุชื่อวารสาร'" :textValues="Values.paper_journal" 
                                    :onChanged="(v) => (this.Values.paper_journal = v)"  />
                                    <span v-if="errors.paper_journal" class="error-message">{{ errors.paper_journal }}</span>
                            </a-col>

                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ชื่อผู้จัดทำ<span class="text-danger">*</span></label>
                                <AutoCompleteUC  placeholder="ชื่อผู้จัดทำ"
                                    :options="paper_owner_id" :onChanged="(v) => (Values.paper_owner_id = v)"
                                    :optionValue="Values.paper_owner_id" />
                                    <span v-if="errors.paper_owner_id" class="error-message">{{ errors.paper_owner_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <label>บทบาทผู้จัดทำ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="paper_owner_role" 
                                    :placeholder="'ระบุบทบาทผู้จัดทำ'" :textValues="Values.paper_owner_role" 
                                    :onChanged="(v) => (this.Values.paper_owner_role = v)" />
                                    <span v-if="errors.paper_owner_role" class="error-message">{{ errors.paper_owner_role }}</span>
                            </a-col>
                            
                            <a-col :lg="8" :md="8" :xs="24">
                                <label>ฐานข้อมูลวารสาร<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="paper_database" 
                                    :placeholder="'ฐานข้อมูลวารสาร'" :textValues="Values.paper_database" 
                                    :onChanged="(v) => (this.Values.paper_database = v)"  />
                                    <span v-if="errors.paper_database" class="error-message">{{ errors.paper_database }}</span>
                            </a-col>
                            <a-col :lg="8" :md="8" :xs="24">
                                <label>ระดับวารสาร<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="paper_ranking" 
                                    :placeholder="'ระดับวารสาร'" :textValues="Values.paper_ranking" 
                                    :onChanged="(v) => (this.Values.paper_ranking = v)" />
                                    <span v-if="errors.paper_ranking" class="error-message">{{ errors.paper_ranking }}</span>
                            </a-col>
                            <a-col :lg="4" :md="4" :xs="24">
                                <label>ลำดับ<span class="text-danger">*</span></label>
                                <TextInputUCVue :v-model="record_order" 
                                    :placeholder="'ลำดับ'" :textValues="Values.record_order" 
                                    :onChanged="(v) => (this.Values.record_order = v)"  />
                                    <span v-if="errors.record_order" class="error-message">{{ errors.record_order }}</span>
                            </a-col>

                            <!-- <a-col :lg="24" :md="24" :xs="24">
                            <a-row :gutter="[16, 16]" v-for="(row, index) in rows" :key="index">
                            <a-col :lg="12" :md="12" :xs="24">
                                <TextInputUCVue :v-model="paper_name" :label="'ชื่อบทความ'"
                                    :placeholder="'ระบุชื่อบทความ'" />
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <TextInputUCVue :v-model="newRow.paper_journal" :label="'ชื่อวารสาร'"
                                    :placeholder="'ระบุชื่อวารสาร'"  />
                            </a-col>

                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <TextInputUCVue :v-model="newRow.paper_owner_id" :label="'ชื่อผู้จัดทำ'"
                                    :placeholder="'ชื่อผู้จัดทำ'"  />
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="24">
                                <TextInputUCVue :v-model="newRow.paper_owner_role" :label="'บทบาทผู้จัดทำ'"
                                    :placeholder="'ระบุบทบาทผู้จัดทำ'"  />
                            </a-col>
                            
                            <a-col :lg="8" :md="8" :xs="24">
                                <TextInputUCVue :v-model="newRow.paper_database" :label="'ฐานข้อมูลวารสาร'"
                                    :placeholder="'ฐานข้อมูลวารสาร'"  />
                            </a-col>
                            <a-col :lg="8" :md="8" :xs="24">
                                <TextInputUCVue :v-model="newRow.paper_ranking" :label="'	ระดับวารสาร'"
                                    :placeholder="'ระดับวารสาร'"  />
                            </a-col>
                            <a-col :lg="4" :md="4" :xs="24">
                                <TextInputUCVue :v-model="newRow.record_order" :label="'ลำดับ'"
                                    :placeholder="'ลำดับ'"  />
                            </a-col>
                            <a-col :lg="4" :md="4" :xs="24" style="margin-top: 15px;">
                                <button type="button" style="border-radius: 40px;
                                background-color: rgb(255, 255, 255);
                                border: 2px solid rgb(240, 101, 153);
                                color: rgb(240, 101, 153);
                                padding: 6px 16px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 0.5rem;
                                width: 100%;" @click="deleteRow(index)">ลบแถว</button>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24">
                                <br/>
                            </a-col>
                            </a-row>
                            <a-col :lg="4" :md="4" :xs="24">
                                <button type="button" style="border-radius: 40px;
                                background-color: rgb(255, 255, 255);
                                border: 2px solid rgb(240, 101, 153);
                                color: rgb(240, 101, 153);
                                padding: 6px 16px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 0.5rem;
                                width: 100%;" @click="addRow">เพิ่มแถว</button>
                            </a-col>
                            </a-col> -->
                            
                            

                            <a-col :lg="24" :md="24" :xs="24">
                                <br/>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>ระบุข้อมูลส่วนท้ายของบันทึกข้อความ</b></a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ผู้ลงนามบันทึกข้อความ<span class="text-danger">*</span></label>
                                <AutoCompleteUC :v-model="letter_sign_id" placeholder="ระบุผู้ลงนามบันทึกข้อความ"
                                    :options="letter_sign_id" :onChanged="(v) => (Values.letter_sign_id = v)"
                                    :optionValue="Values.letter_sign_id" />
                                    <span v-if="errors.letter_sign_id" class="error-message">{{ errors.letter_sign_id }}</span>
                            </a-col>
                            <a-col :lg="12" :md="12" :xs="12" class="gutter-row">
                                <label>ตำแหน่งผู้ลงนามบันทึกข้อความ<span class="text-danger">*</span></label>
                                <AutoCompleteUC :v-model="letter_sign_position_id" 
                                    placeholder="ระบุตำแหน่งผู้ลงนามบันทึกข้อความ" :options="letter_sign_position_id"
                                    :onChanged="(v) => (Values.letter_sign_position_id = v)"
                                    :optionValue="Values.letter_sign_position_id" />
                                    <span v-if="errors.letter_sign_position_id" class="error-message">{{ errors.letter_sign_position_id }}</span>
                            </a-col>


                            <a-col :lg="24" :md="24" :xs="24">
                                <br/>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24"><b>เอกสารแนบ (ถ้ามี)</b> <p :style="{ fontSize: '14px', opacity: '0.6' }">
                                ขนาดไฟล์ไม่เกิน 8 MB
                              </p></a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange1" />
                                    เอกสารแนบ 1 (ถ้ามี) : <span v-if="selectedFile1" style="color: #4691f6;">{{ selectedFile1.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;"/>
                                    
                                </label>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange2"  />
                                    เอกสารแนบ 2 (ถ้ามี) : <span v-if="selectedFile2" style="color: #4691f6;">{{ selectedFile2.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;"/>
                                </label>
                            </a-col>
                            <a-col :lg="24" :md="24" :xs="24" class="gutter-row">
                                <label class="custom-file-upload">
                                    <input type="file" @change="handleFileChange3"  />
                                    เอกสารแนบ 3 (ถ้ามี) : <span v-if="selectedFile3" style="color: #4691f6;">{{ selectedFile3.name }}</span>
                                    <BaseIcon :name="'browseIcon'" style="float: inline-end;"/>
                                </label>
                            </a-col>

                        </a-row>
                    </a-col>

                </a-row>

                <a-row :gutter="[16]">
                    <a-col :lg="8" :md="12" :xs="24" class="gutter-row">
                        <a-row :gutter="[16, 16]" class="h-full">
                            <a-col :md="12">
                                <ButtonUCVue type="submit" :header="'test'" :label="'บันทึกข้อมูล'" :isBg="true"
                                    :iconName="'SaveIcon'" :iconColor="'#FFF'" />
                            </a-col>
                            <!-- <a-col :md="12">
                                <ButtonUCVue type="button" header="'header'" :label="'ล้างค่า'" :onClicked="() => handleClear()"
                                    :iconName="'refreshIcon'" :iconColor="'#ED4081'" />
                            </a-col> -->
                        </a-row>
                    </a-col>
                </a-row>
            </form>
        </div>


        <!-- Button Actions -->
        <div class="w-full bg-white mt-2 p-4 boxShadow-main flex gap-4 items-center justify-end sticky bottom-0"
            :style="{ borderRadius: '10px', height: '100%' }">
            <div :style="{ minWidth: '10rem' }">
                <ButtonUCVue label="ย้อนกลับ" :onClicked="backPage" />
            </div>
        </div>
    </div>
</template>
<script>
import ButtonUCVue from "../../components/ButtonUC.vue";
import TextInputUCVue from "../../components/TextInputUC.vue";
import AutoCompleteUC from "../../components/AutoCompleteUC.vue";
import DatePickkerUCVue from "../../components/DatePickkerUC.vue";
import BaseIcon from "../../components/Icon/BaseIcon.vue";



import { HTTP } from "../../http-common";


import dayjs from "dayjs";
import moment from "moment";
import axios from 'axios';

export default {
    name: "ReportSignPositionAdd",
    components: {
        TextInputUCVue,
        ButtonUCVue,
        AutoCompleteUC,
        DatePickkerUCVue,
        BaseIcon,
    },

    mounted() {

        this.fetchSignList();

        //this.$store.commit("setBread", this.$router.currentRoute._value);
        this.$store.commit("setChild", this.$router.currentRoute._value);
    },


    // mounted() {
    //     if (this.$router.currentRoute._rawValue.params.project_id) {
    //     this.project_id = this.$router.currentRoute._rawValue.params.project_id;
    //     this.getResearchById(this.project_id);
    //     }
    //     this.$store.commit("setChild", this.$router.currentRoute._value);
    // },

    unmounted() {
        //this.$store.commit("setClearFileSave");
        this.$store.commit("setChild", "");
    },

    methods: {
        // async addRow() {
        //     // this.rows.push({ paper_name: ''
        //     //     , paper_journal: ''
        //     //     , paper_owner_id: ''
        //     //     , paper_owner_role: ''
        //     //     , paper_database: ''
        //     //     , paper_ranking: ''
        //     //     , record_order: ''
        //     //  });


        //      // ตรวจสอบว่าข้อมูลใน newRow ไม่ว่างเปล่า
        //     //if (this.newRow.paper_name) {
        //         // เพิ่มแถวใหม่ใน rows array
        //         this.rows.push({ ...this.newRow });
        //         this.newRow = { paper_name: '', paper_journal: '', paper_owner_id: '' };
        //         // alert('Row added:', this.rows); // ตรวจสอบค่าของ this.rows

        //         // เคลียร์ input fields หลังจากเพิ่มแถวแล้ว
        //         // this.newRow = {
        //         //     paper_name: ''
        //         //     , paper_journal: ''
        //         //     , paper_owner_id: ''
        //         //     , paper_owner_role: ''
        //         //     , paper_database: ''
        //         //     , paper_ranking: ''
        //         //     , record_order: ''
        //         // };
        //     //}

            
        // },
        // async deleteRow(index) {
        //     this.rows.splice(index, 1);
        // },
        handleFileChange1(event) {
            this.Values.attach_1 = event.target.files[0];
            this.selectedFile1 = this.Values.attach_1;
        },
        handleFileChange2(event) {
            this.Values.attach_2 = event.target.files[0];
            this.selectedFile2 = this.Values.attach_2;
        },
        handleFileChange3(event) {
            this.Values.attach_3 = event.target.files[0];
            this.selectedFile3 = this.Values.attach_3;
        },
     
        async fetchSignList() {
            let loading = this.$loading.show();
            const { data } = await HTTP.post("/getReportSignList");
            this.letter_sign_id = data.sign_id;
            this.letter_sign_position_id = data.sign_position_id;
            this.paper_owner_id = data.research_name;
            loading.hide();
        },

        async backPage() {
            this.$router.push(`/report-published`);
        },
        async handleClear() {
            // this.rows = [
            //     { paper_name: ''
            //     , paper_journal: ''
            //     , paper_owner_id: ''
            //     , paper_owner_role: ''
            //     , paper_database: ''
            //     , paper_ranking: ''
            //     , record_order: '' }
            // ],
            this.Values = {
                letter_code :"",
                letter_date: "",
                letter_to: "",
                letter_sign_id: "",
                letter_sign_position_id: "",
                paper_name: "",
                paper_journal: "",
                paper_owner_id: "",
                paper_owner_role: "",
                paper_database: "",
                paper_ranking: "",
                record_order: "",
            };

        },
        async submitForm() {

            const formData = new FormData();

            formData.append("letter_code", this.Values.letter_code);
            formData.append("letter_date", moment(new Date(this.stepDueDate))
                .format("YYYY-MM-DD"));
            formData.append("letter_to", this.Values.letter_to);
            formData.append("letter_sign_id", this.Values.letter_sign_id);
            formData.append("letter_sign_position_id", this.Values.letter_sign_position_id);
            formData.append("paper_name", this.Values.paper_name);
            formData.append("paper_journal", this.Values.paper_journal);
            formData.append("paper_owner_id", this.Values.paper_owner_id);
            formData.append("paper_owner_role", this.Values.paper_owner_role);
            formData.append("paper_database", this.Values.paper_database);
            formData.append("paper_ranking", this.Values.paper_ranking);
            formData.append("record_order", this.Values.record_order);

            /* has :: attach_1 */
            if (this.Values.attach_1) {
                formData.append("attach_1", this.Values.attach_1);
            } else {
                console.log('No attach_1 file selected.');
            }

            /* has :: attach_2 */
            if (this.Values.attach_2) {
                formData.append("attach_2", this.Values.attach_2);
            } else {
                console.log('No attach_2 file selected.');
            }

            /* has :: attach_3 */
            if (this.Values.attach_3) {
                formData.append("attach_3", this.Values.attach_3);
            } else {
                console.log('No attach_3 file selected.');
            }



                try {

                    let isValid = true;


                    if (!this.Values.letter_to) {
                        this.errors.letter_to = 'ระบุเรียน';
                        isValid = false;
                    }
                    if (!this.Values.paper_name) {
                        this.errors.paper_name = 'ระบุชื่อบทความ';
                        isValid = false;
                    }
                    if (!this.Values.paper_journal) {
                        this.errors.paper_journal = 'ระบุชื่อวารสาร';
                        isValid = false;
                    }
                    if (!this.Values.paper_owner_id) {
                        this.errors.paper_owner_id = 'ระบุชื่อผู้จัดทำ';
                        isValid = false;
                    }
                    if (!this.Values.paper_owner_role) {
                        this.errors.paper_owner_role = 'ระบุบทบาทผู้จัดทำ';
                        isValid = false;
                    }
                    if (!this.Values.paper_database) {
                        this.errors.paper_database = 'ระบุฐานข้อมูลวารสาร';
                        isValid = false;
                    }
                    if (!this.Values.paper_ranking) {
                        this.errors.paper_ranking = 'ระบุระดับวารสาร';
                        isValid = false;
                    }
                    if (!this.Values.record_order) {
                        this.errors.record_order = 'ระบุลำดับ';
                        isValid = false;
                    }

                    if (!this.Values.letter_sign_id) {
                        this.errors.letter_sign_id = 'ระบุผู้ลงนามหนังสือเชิญ';
                        isValid = false;
                    }
                    if (!this.Values.letter_sign_position_id) {
                        this.errors.letter_sign_position_id = 'ระบุตำแหน่งผู้ลงนามหนังสือเชิญ';
                        isValid = false;
                    }


                    if (isValid) {
                    
                    await HTTP.post('/InsertReportPublished', formData, {
                    })
                        .then(response => {
                            this.$swal.fire({
                                title: "บันทึกสำเร็จ",
                                width: 300,
                                height: 300,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 3000,
                            }).then((result) => {
                                this.$router.push(`/report-published`);
                            });
                        })
                        .catch(error => {
                            console.error('Error uploading file:' + error);
                        });

                }else{
                    console.error('There was an validate!', error);
                }

                } catch (error) {
                    console.error('There was an error!', error);
                }

           


 
            
        },

    },

    data() {
        return {
            errors: {},      // เก็บ error message ของ validation
            // rows: [
            //     { paper_name: ''
            //     , paper_journal: ''
            //     , paper_owner_id: ''
            //     , paper_owner_role: ''
            //     , paper_database: ''
            //     , paper_ranking: ''
            //     , record_order: '' }
            // ],
            rows: [],          // เก็บข้อมูลทั้งหมดที่ถูกเพิ่ม
            // newRow: {          // เก็บค่าจาก input fields
            //     paper_name: ''
            //     , paper_journal: ''
            //     , paper_owner_id: ''
            //     , paper_owner_role: ''
            //     , paper_database: ''
            //     , paper_ranking: ''
            //     , record_order: ''
            // },
            selectedFile1: null,
            selectedFile2: null,
            selectedFile3: null,
            loader: "",
            letter_sign_id: [],
            letter_sign_position_id: [],
            paper_owner_id:[],
            stepDueDate:null,
            Values: {
                letter_code :"",
                letter_date: "",
                letter_to: "",
                letter_sign_id: "",
                letter_sign_position_id: "",
                paper_name: "",
                paper_journal: "",
                paper_owner_id: "",
                paper_owner_role: "",
                paper_database: "",
                paper_ranking: "",
                record_order: "",
                attach_1: "",
                attach_2: "",
                attach_3: "",
            },
        };
    },



};
</script>

<style scoped>
.text-danger{
    color:red;
}
.error-message{
    color:red;
    font-size: 12px;
}
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border: 2px solid #4CAF50;
  border-radius: 50px;
  border-color: #ddd;
  color: ddd;
  font-weight: bold;
  width: 100%;
}

.custom-file-upload input[type="file"] {
  display: none;
}

button {
  margin-top: 10px;
  padding: 6px 12px;
  border: none;
  background-color: #2196F3;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0b7dda;
}
</style>